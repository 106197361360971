
//*****************************************************************************

import * as getVar from "../common/page.vars.js";
import * as getFunc from "../common/page.functions.js";

//*****************************************************************************

export const ctrMapOverlay = ( function () {

 const getElemId = document.getElementById.bind(document);
 const getElemClass = document.querySelector.bind(document);
 const getElemClassAll = document.querySelectorAll.bind(document);

 let selectMapOverlay = null;
 let selectAlertOverlay = null;

 let selectAlertText = null;
 let selectButtonAgree = null;
 let selectButtonClose = null;

 let selectCloseElements = null;

 const viewportXs = 0;
 const viewportSm = 576;
 const viewportMd = 768;
 const viewportSl = 992;
 const viewportMl = 1200;
 const viewportXl = 1400;

 const btnAgreeTextShort = "OK";
 const btnAgreeTextLong = "Zu Google Maps";

 const openOverlayAnimate = "animate_fadeIn";
 const openOverlayDuration = "0.05s";

 const closeOverlayAnimate = "animate_fadeOut";
 const closeOverlayDuration = "0.1s";

 const setTimeoutLayer = 80;

 const selectGoogleMap = getElemClass(".google-maps .map");

 const classListOverlay = ["map-overlay","alert-overlay","alert-text","buttons"];

 const googleLink = '<a href="https://policies.google.com/privacy" target="_blank" title="Datenschutzerklärung">Datenschutzerklärung von Google</a>';

 const infoTextShort = "Bei der Verwendung der Kartenfunktion akzeptieren Sie die " + googleLink + ".";

 const infoTextLong = "Bei der Verwendung der Kartenfunktion akzeptieren Sie die " + googleLink + ". Durch " +
                      "die Nutzung von Google Maps können Informationen über Ihre Nutzung unserer Webseite " +
                      "an Google in den USA übertragen und dort gespeichert werden.";

 const googleMapIframe = '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.8425459254063!2d11.599819047926177!3d48.13144300010869!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479e757fd7ed0089%3A0x9766d2b7ac2acdad!2sPreysingstra%C3%9Fe%20103%2C%2081667%20M%C3%BCnchen%2C%20Deutschland!5e0!3m2!1sde!2sus!4v1652483361580!5m2!1sde!2sus" style="border:0;width:100%;height:100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>';

 const mediaQueryXs = window.matchMedia("(min-width: " + viewportXs + "px) and (max-width: " + (viewportMd - 1) + "px)");
 const mediaQueryMd = window.matchMedia("(min-width: " + viewportMd + "px)");

 //****************************************************************************

 const setGoogleMapsOverlay = function () {

  let mapOverlay = document.createElement("div");
  mapOverlay.classList.add("map-overlay");

  let alertOverlay = document.createElement("div");
  alertOverlay.classList.add("alert-overlay");

  let xIcon = document.createElement("div");
  xIcon.classList.add("x-icon","close");
  alertOverlay.appendChild(xIcon);

  let alertText = document.createElement("div");
  alertText.classList.add("alert-text");
  alertOverlay.appendChild(alertText);

  let buttons = document.createElement("div");
  buttons.classList.add("buttons");

  let btn = document.createElement("button");
  btn.setAttribute("type","button");
  btn.classList.add("btn","btn-agree");
  btn.innerHTML = "";
  buttons.appendChild(btn);

  btn = document.createElement("button");
  btn.setAttribute("type","button");
  btn.classList.add("btn","btn-disagree","close");
  btn.innerHTML = "Abbrechen";
  buttons.appendChild(btn);

  alertOverlay.appendChild(buttons);
  mapOverlay.appendChild(alertOverlay);
  selectGoogleMap.appendChild(mapOverlay);

  selectMapOverlay = getElemClass(".google-maps .map .map-overlay");
  selectAlertOverlay = getElemClass(".google-maps .map .alert-overlay");

  selectAlertText = getElemClass(".google-maps .map .alert-text");
  selectButtonAgree = getElemClass(".google-maps .map .btn-agree");

  selectCloseElements = getElemClassAll(".google-maps .map .close");
  selectButtonClose = getElemClass(".google-maps .map .x-icon");

  mediaQueryXs.addListener(setMediaQueryXs);
  mediaQueryMd.addListener(setMediaQueryMd);

  setInitMediaQuery();

  setMapOverlay();

  clickButtonAgree();
  clickButtonClose();

 }

 //****************************************************************************

 const setInitMediaQuery = function() {

  if(mediaQueryXs.matches) setMediaQuery("short"); else setMediaQuery("long");

 }

 //****************************************************************************

 const setMediaQueryXs = function(evt) {

  if(evt.matches) {	setMediaQuery("short"); }

 }

 //****************************************************************************

 const setMediaQueryMd = function(evt) {

  if(evt.matches) {	setMediaQuery("long"); }

 }

 //****************************************************************************

 const setMediaQuery = function(view) {

  if(view == "short") {

	 selectAlertText.innerHTML = infoTextShort;
	 selectButtonAgree.innerHTML = btnAgreeTextShort;

  } else {

	 selectAlertText.innerHTML = infoTextLong;
	 selectButtonAgree.innerHTML = btnAgreeTextLong;

  }

 }

 //****************************************************************************

 const setMapOverlay = function() {

  openMapOverlay(selectMapOverlay,openOverlayAnimate,openOverlayDuration);

  setTimeout( function(){

   openMapOverlay(selectAlertOverlay,openOverlayAnimate,openOverlayDuration);

   window.addEventListener("click",clickOverlayOutside);

  }, setTimeoutLayer);

 }

 //****************************************************************************

 const clickButtonAgree = function() {

  selectButtonAgree.addEventListener("click", function() {

   closeMapOverlay(selectAlertOverlay,closeOverlayAnimate,closeOverlayDuration);

   setTimeout( function(){

    closeMapOverlay(selectMapOverlay,closeOverlayAnimate,closeOverlayDuration);

   }, setTimeoutLayer);

   setGoogleMapFrame();

  });

 }

 //****************************************************************************

 const clickButtonClose = function() {

  selectCloseElements.forEach( function (elem) {

   elem.addEventListener("click", function() {

    closeMapOverlay(selectAlertOverlay,closeOverlayAnimate,closeOverlayDuration);

    setTimeout( function(){

     closeMapOverlay(selectMapOverlay,closeOverlayAnimate,closeOverlayDuration);

    }, setTimeoutLayer);

   });

  });

 }

 //****************************************************************************

 const openMapOverlay = function(elem,animation,duration) {

  elem.classList.add(animation);
  elem.style.setProperty("animation-duration",duration);
  elem.style.setProperty("opacity","1");

  elem.addEventListener("animationend", function animate () {

   if(elem.classList.contains(animation)) {

    elem.classList.remove(animation);
    elem.style.removeProperty("animation-duration",duration);

    elem.removeEventListener("animationend",animate);

   }

  });

 }

 //****************************************************************************

 const closeMapOverlay = function(elem,animation,duration) {

  elem.classList.add(animation);
  elem.style.setProperty("animation-duration",duration);

  elem.addEventListener("animationend", function animate () {

   if(elem.classList.contains(animation)) {

    elem.classList.remove(animation);
    elem.style.removeProperty("animation-duration",duration);
    elem.removeAttribute("style");

    if(elem == selectMapOverlay) {

     removeDocumentElement(elem);
     setClickGoogleMap();

    }

    elem.removeEventListener("animationend",animate);

    window.removeEventListener("click",clickOverlayOutside);

   }

  });

 }

 //****************************************************************************

 const removeDocumentElement = function (elem) {

  const node = document.getElementsByClassName(elem.className);

  while(node.length > 0) { node[0].parentNode.removeChild(node[0]); }

 }

 //****************************************************************************

 const setClickGoogleMap = function () {

  selectGoogleMap.addEventListener("click", function clicked () {

   selectGoogleMap.removeEventListener("click",clicked);

   setGoogleMapsOverlay();

  });

 }

 //****************************************************************************

 const setGoogleMapFrame = function () {

  selectGoogleMap.style.setProperty("background","none");
  selectGoogleMap.style.setProperty("cursor","default");

  selectGoogleMap.innerHTML = "";
  selectGoogleMap.innerHTML = googleMapIframe;

 }

 //****************************************************************************

 const clickOverlayOutside = function(evt) {

  let elemClassName = evt.target.parentNode.className;

  if(classListOverlay.indexOf(elemClassName) == -1) { selectButtonClose.click(); }

 }

 //****************************************************************************

 const init = function() { setClickGoogleMap(); };

 return {

  init : function () { init(); }

 };

});

//*****************************************************************************

const setMapOverlay = new ctrMapOverlay;

setMapOverlay.init();

//*****************************************************************************
