
//*****************************************************************************

import * as getVar from "../common/page.vars.js";
import * as getFunc from "../common/page.functions.js";

//*****************************************************************************

export const ctrImageLightbox = ( function () {

 "use strict";

 let arrUrlItems = [];
 let arrDescItems = [];

 let arrContainerItems = [];

 let clickItemIndex = 0;
 let clickItemElements = 0;

 let clickItemUrl = "";
 let clickItemCount = "";
 let clickItemDesc = "";

 let clickItemSpace = 0;
 let clickItemWidth = 0;
 let clickItemHeight = 0;

 let matchMediaItem = null;
 let matchMediaRatio = null;

 let setTransition = true;
 let selectGoToTop = null;

 const selectLightboxBody = document.querySelector("body");

 const selectPluginContainer = document.querySelector(".lightbox");

 const selectLightboxOverlay = document.querySelector(".lightbox-overlay");
 const selectLightboxContainer = document.querySelector(".lightbox-container");
 const selectLightboxClose = document.querySelector(".lightbox-container .lightbox-close");

 const selectLightboxContent = document.querySelector(".lightbox-content");
 const selectLightboxDisplay = document.querySelector(".lightbox-display");
 const selectLightboxDesc = document.querySelector(".lightbox-description p");
 const selectLightboxCount = document.querySelector(".lightbox-items-count p");

 const selectLightboxOverBackward = document.querySelector(".lightbox-over-backward");
 const selectLightboxOverForward = document.querySelector(".lightbox-over-forward");
 const selectLightboxSwitchButtons = document.querySelector(".lightbox-items-switch");
 const selectLightboxSwitchBackward = document.querySelector(".lightbox-items-switch .btn-backward");
 const selectLightboxSwitchForward = document.querySelector(".lightbox-items-switch .btn-forward");
 const selectLightboxItemsCount = document.querySelector(".lightbox-items-count");

 const getGoToTopButtons = function() {

  if(document.getElementsByClassName("btns-go-top").length) {

   selectGoToTop = document.querySelector(".btns-go-top");
   return true;

  }

  return false;

 }

 const getLightboxItems = function(param) {

  let elem = param.parentNode.closest(".lightbox-items")

  let childs = elem.children.length;

  clickItemElements = (childs - 1);

  arrUrlItems = [];
  arrDescItems = [];

  for(let i=0; i < childs; i++) {

   let url = elem.children[i].querySelector(".lightbox-lnk").getAttribute("href");
   let desc = elem.children[i].querySelector(".lightbox-desc").innerHTML;

   arrUrlItems.push(url);
   arrDescItems.push(desc);

  }

  getLightboxItemData();

 }

 const getLightboxItemData = function(e) {

  clickItemUrl = arrUrlItems[clickItemIndex];
  clickItemDesc = arrDescItems[clickItemIndex];

  let showItem = (clickItemIndex + 1);
  let totalItems = (clickItemElements + 1);

  clickItemCount = zeroNumPad(showItem,2) + "/" + zeroNumPad(totalItems,2);

  if(totalItems > 1) { controlSwitchButtons(true); } else { controlSwitchButtons(false); }

  let getLightboxVisibility = window.getComputedStyle(selectLightboxContainer).visibility;

  if(getLightboxVisibility == "hidden") {

   openOverlay(selectLightboxOverlay,"animate_fadeIn","0.3s"); } else {

   switchLightboxItem();

  }

 }

 const getLightboxItemSize = function() {

  let url = arrUrlItems[clickItemIndex];

  getImageSize(url, function(imgWidth,imgHeight) {

  if(clickItemWidth !== imgWidth || clickItemHeight !== imgHeight)
  setTransition = true; else setTransition = false;

  clickItemWidth = imgWidth;
  clickItemHeight = imgHeight;

   matchMediaRatio = "(min-aspect-ratio: " + imgWidth + "/" + imgHeight +")";

   if(imgWidth >= imgHeight) clickItemSpace = 60; else clickItemSpace = 70;

   matchMediaItem = window.matchMedia(matchMediaRatio);
   matchMediaItem.addListener(setRatioProperty);

   setRatioProperty(matchMediaItem);

  });

  setLightboxItem();

 }

 const setLightboxItem = function() {

  selectLightboxCount.innerHTML = clickItemCount;
  selectLightboxDesc.innerHTML = clickItemDesc;

  const img = document.createElement("img");
  img.src = clickItemUrl;

  selectLightboxDisplay.innerHTML = "";
  selectLightboxDisplay.appendChild(img);

 }

 const switchLightboxItem = function() {

  matchMediaItem.removeListener(setRatioProperty);

  closeLightboxItem(selectLightboxDisplay,"animate_fadeOut","0.3s");

 }

 const closeLightboxItem = function(elem,anim_class,duration) {

  elem.classList.add(anim_class);
  elem.style.setProperty("animation-duration",duration);

  elem.addEventListener("animationend", function animate () {

   if(elem.classList.contains(anim_class)) {

    elem.classList.remove(anim_class);
    elem.style.removeProperty("animation-duration",duration);
    elem.style.setProperty("visibility","hidden");

    elem.removeEventListener("animationend",animate);

   }

  });

  setAnimateTransition(selectLightboxContent,"animate-transition");

 }

 const openLightboxItem = function(elem,anim_class,duration) {

  elem.classList.add(anim_class);
  elem.style.setProperty("animation-duration",duration);
  elem.style.setProperty("visibility","visible");

  elem.addEventListener("animationend", function animate () {

   if(elem.classList.contains(anim_class)) {

    elem.classList.remove(anim_class);
    elem.style.removeProperty("animation-duration",duration);

    elem.removeEventListener("animationend",animate);

   }

  });

 }

 const setAnimateTransition = function(elem,anim_class) {

  if(!setTransition) {

   setLightboxItem();

   openLightboxItem(selectLightboxDisplay,"animate_fadeIn","0.3s");

  } else {

   elem.classList.add(anim_class);

   elem.addEventListener("transitionend", function animate () {

    elem.classList.remove(anim_class);
    elem.removeEventListener("transitionend",animate);

    setLightboxItem();

    openLightboxItem(selectLightboxDisplay,"animate_fadeIn","0.3s");

   });

  }

 }

 const goItemBackward = function(e) {

  if(clickItemIndex == 0) { clickItemIndex = clickItemElements; } else { clickItemIndex--; }

  getLightboxItemSize();
  getLightboxItemData();

 }

 const goItemForward = function(e) {

  if(clickItemElements == clickItemIndex) { clickItemIndex = 0; } else { clickItemIndex++; }

  getLightboxItemSize();
  getLightboxItemData();

 }

 const controlSwitchButtons = function(act) {

  if(act == true) {

   selectLightboxOverBackward.removeAttribute("style");
   selectLightboxOverForward.removeAttribute("style");
   selectLightboxSwitchButtons.removeAttribute("style");
   selectLightboxItemsCount.removeAttribute("style");

  } else {

   selectLightboxOverBackward.style.setProperty("display","none");
   selectLightboxOverForward.style.setProperty("display","none");
   selectLightboxSwitchButtons.style.setProperty("display","none");
   selectLightboxItemsCount.style.setProperty("display","none");

  }

 }

 const setPreloadImages = function() {

  if(checkPreloadImages() == false) {

   let countImages = 0;

   setPreloader();

   arrUrlItems.forEach ( function (urlItem, urlIndex) {

    const img = new Image();
    img.src = arrUrlItems[urlIndex];

    img.onload = function() {

     countImages++;

     if(countImages >= arrUrlItems.length) {

      setTimeout( () => {

       removePreloader();

       getLightboxItemSize();
       openOverlay(selectLightboxContainer,"animate_fadeInUp","0.3s");

      }, 300);

     }

    }

   });

  } else {

   getLightboxItemSize();
   openOverlay(selectLightboxContainer,"animate_fadeInUp","0.3s");

  }

 }

 const checkPreloadImages = function() {

  let preload = true;

  arrUrlItems.forEach ( function (value, index) {

   const img = new Image();
   img.src = arrUrlItems[index];

   if(!img.complete && preload == true) { preload = false; }

  });

  return preload;

 }

 const getImageSize = function(url,callback) {

  let img = new Image();
  img.src = url;
  img.onload = function() { callback(this.width,this.height); }

 }

 const zeroNumPad = function(num, fill) {

  const numZeroes = fill - num.toString().length + 1;

  if(numZeroes > 0) { return Array(+numZeroes).join("0") + num; }

  return num

 }

 const closeContainerLightbox = function() {

  matchMediaItem.removeListener(setRatioProperty);

  closeOverlay(selectLightboxContainer,"animate_fadeOutDown","0.3s");
  closeOverlay(selectLightboxOverlay,"animate_fadeOut","0.3s");

 }

 const openOverlay = function(elem,anim_class,duration) {

  elem.classList.add(anim_class);
  elem.style.setProperty("animation-duration",duration);
  elem.style.setProperty("visibility","visible");

  if(elem == selectLightboxOverlay) {

   if(getGoToTopButtons()) selectGoToTop.style.setProperty("display","none");

   selectLightboxBody.style.setProperty("padding-right",getScrollBarWidth() + "px");
   selectLightboxBody.style.setProperty("overflow","hidden","important");
   selectPluginContainer.style.setProperty("display","block");

  }

  elem.addEventListener("animationend", function animate () {

   if(elem.classList.contains(anim_class)) {

    elem.classList.remove(anim_class);
    elem.style.removeProperty("animation-duration",duration);

    if(elem == selectLightboxOverlay) { setPreloadImages(); }

    elem.removeEventListener("animationend",animate);

   }

  });

 }

 const closeOverlay = function(elem,anim_class,duration) {

  elem.classList.add(anim_class);
  elem.style.setProperty("animation-duration",duration);

  elem.addEventListener("animationend", function animate () {

   if(elem.classList.contains(anim_class)) {

    elem.classList.remove(anim_class);
    elem.style.removeProperty("animation-duration",duration);
    elem.removeAttribute("style");

    if(elem == selectLightboxOverlay) {

     selectLightboxBody.style.removeProperty("padding-right",getScrollBarWidth() + "px");
     selectLightboxBody.style.removeProperty("overflow","hidden","important");
     selectLightboxBody.removeAttribute("style");

     if(getGoToTopButtons()) selectGoToTop.style.setProperty("display","block");

     removeElemStyles(selectPluginContainer);

     controlSwitchButtons(false);

    }

    elem.removeEventListener("animationend",animate);

   }

  });

 }

 const removeElemStyles = function (elem) {

  elem.removeAttribute("style");

  if(elem.childNodes.length > 0) {

   for(let child in elem.childNodes) {

    if(elem.childNodes[child].nodeType == 1) { removeElemStyles(elem.childNodes[child]); }

   }

  }

 }

 const setRatioProperty = function(e) {

  if(e.matches) {

   selectLightboxContent.style.setProperty("height","calc(1vh * " + clickItemSpace + ")");
   selectLightboxContent.style.setProperty("width","calc(1vh * (" + clickItemSpace +" * (" + clickItemWidth +" / " + clickItemHeight +")))");

  } else {

   selectLightboxContent.style.setProperty("height","calc(1vw * (" + clickItemSpace +" * (" + clickItemHeight +" / " + clickItemWidth +")))");
   selectLightboxContent.style.setProperty("width","calc(1vw * " + clickItemSpace + ")");

  }

 }

 const getLinkItem = function(str) { return str.substring(str.lastIndexOf("/") + 1); }

 const getRelAttribute = function(param) { return document.querySelectorAll("[rel=" + param + "]"); }

 const getLightboxContainer = function(param) { return document.querySelectorAll("[class=" + param + "]"); }

 const getScrollBarWidth = function() { return window.innerWidth - document.documentElement.clientWidth; }

 const setPreloader = function () {

  const preloader = document.createElement("div");
  preloader.classList.add("lightbox-preloader");

  document.body.appendChild(preloader);

 }

 const removePreloader = function () {

  const preloader = document.querySelector(".lightbox-preloader")

  setTimeout( function(){

   preloader.parentNode.removeChild(preloader);

  }, 50);

 }

 const init = function() {

  let arrLightboxContainer = getLightboxContainer("lightbox-items");

  arrLightboxContainer.forEach( (lboxItem,lboxIndex) => {

   let arrRelItems = lboxItem.querySelectorAll("[rel=lightbox]");

   arrRelItems.forEach( (relItem,relIndex) => {

    relItem.addEventListener("click", function(evt) {

     evt.stopPropagation();
     evt.preventDefault();

     arrContainerItems[lboxIndex] = new Array(relItem);
     clickItemIndex = relIndex;

     getLightboxItems(relItem);

    });

   });

  });

  selectLightboxClose.addEventListener("click", function() {

   closeContainerLightbox();

  });

  selectLightboxOverBackward.addEventListener("click",goItemBackward);
  selectLightboxSwitchBackward.addEventListener("click",goItemBackward);
  selectLightboxOverForward.addEventListener("click",goItemForward);
  selectLightboxSwitchForward.addEventListener("click",goItemForward);

 }

 return {

  init : function () { init(); }

 };

});

//*****************************************************************************

// const setImageLightbox = new ctrImageLightbox;
//
// setImageLightbox.init();

//*****************************************************************************
