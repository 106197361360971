
//*****************************************************************************

import * as getVar from "../common/page.vars.js";
import * as getFunc from "../common/page.functions.js";

//*****************************************************************************

export const ctrMobileMenu = ( function () {

 "use strict";

 const ctrMobileMenu = function(obj) {

  const ref = this;

  obj.selectMenuDropdown = document.querySelector("ul.menu li ul");

  ref.ctrToggleMenu = function() {

   if(obj.elem.classList.contains("open")) {

    ref.closeMobileMenu();

   } else {

    if(window.scrollY === 0) { ref.openMobileMenu(); } else {

     getFunc.scrollToTop();

     window.addEventListener("scrollend", function scrolling() {

      ref.openMobileMenu();
      window.removeEventListener("scrollend",scrolling);

     });

    }

   }

  }

  ref.openMobileMenu = function() {

   obj.selectMenuDropdown.style.setProperty("display","none");

   getFunc.setPageOverflow("overlay");

   setTimeout( function() {

    obj.elem.classList.add("open");

    obj.elemNavMenu.style.setProperty("right",getVar.scrollbar + "px");
    obj.elemNavMenu.style.setProperty("transform","translateX(0%)");

    setTimeout( function() { obj.selectMenuDropdown.removeAttribute("style"); }, 0);

   }, 0);

   obj.elemArrExpand.forEach( elem => {

    elem.querySelector(".nav-link").addEventListener("click", () => { elem.classList.add("active"); });
    elem.querySelector(".nav-back-link").addEventListener("click", () => { elem.classList.remove("active"); });

   });

   obj.elemArrClose.forEach( elem  => {

    elem.addEventListener("click", () => { ref.ctrToggleMenu(); });

   });

   obj.elemDataLink.forEach( elem  => {

    elem.addEventListener("click", (evt) => {

     const lnk = evt.target.getAttribute("data-link")
     const currentPage = getFunc.getCurrentPage();

     if(lnk === "/#standort" && currentPage === "#standort") {

      obj.elem.classList.remove("open");
      obj.elemNavMenu.style.setProperty("right","0");
      obj.elemNavMenu.style.setProperty("transform","translateX(100%)");

      getFunc.removePageOverflow("overlay");

     }

    });

   });

  }

  ref.closeMobileMenu = function() {

   setTimeout( function() {

    obj.elem.classList.remove("open");

    obj.elemNavMenu.style.setProperty("right","0");
    obj.elemNavMenu.style.setProperty("transform","translateX(100%)");

    obj.elemNavMenu.addEventListener("transitionend", function transition() {

     getFunc.removePageOverflow("overlay");

     // navExpandReset();

     obj.elemNavMenu.removeEventListener("transitionend",transition);

    });

   }, 0);

  }

  const navExpandReset = function() {

   const navExpand = document.querySelectorAll(".mobile-menu .nav-expand");

   navExpand.forEach( item => {

    if(item.classList.contains("active")) {

     item.classList.remove("active");

    }

   });

  }

 }

 //****************************************************************************

 const init = function() {

  let obj = {};

  obj.elem = document.querySelector(".mobile-menu");

  obj.elemNavMenu = obj.elem.querySelector(".nav-menu");

  obj.elemArrExpand = obj.elem.querySelectorAll(".nav-expand");
  obj.elemArrClose = obj.elem.querySelectorAll(".nav-close");
  obj.elemDataLink = obj.elem.querySelectorAll("[data-link]");

  const ref = new ctrMobileMenu(obj);

  ref.ctrToggleMenu();

 }

 //****************************************************************************

 return {

  init : function () { init(); }

 }

});

//*****************************************************************************

// const setMobileMenu = new ctrMobileMenu;
//
// setMobileMenu.init();

//*****************************************************************************
