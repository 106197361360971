
//*****************************************************************************

import * as getVar from "../common/page.vars.js";
import * as getFunc from "../common/page.functions.js";

//*****************************************************************************

export const ctrPagePreloader = ( function () {

 "use strict";

 const setPreloader = function () {

  const preloader = document.querySelector(".preloader");
  const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;

  getVar.setScrollBar(scrollBarWidth);

  getFunc.setPageOverflow();

  preloader.classList.add("spin");

  window.addEventListener("load", function() {

   setTimeout( function() {

    preloader.classList.add("hide");

    preloader.addEventListener("transitionend", function transition() {

     getFunc.removePageOverflow();

     preloader.remove();

     preloader.removeEventListener("transitionend",transition);

    });

   }, 200);

  });

 }

 //****************************************************************************

 const init = function() { setPreloader(); }

 //****************************************************************************

 return {

  init : function () { init(); }

 }

});

//*****************************************************************************

const setPagePreloader = new ctrPagePreloader;

setPagePreloader.init();

//*****************************************************************************
