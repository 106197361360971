
//*****************************************************************************

const viewport_xs = "(min-width:    0px) and (max-width:  575px)";
const viewport_sm = "(min-width:  576px) and (max-width:  767px)";
const viewport_md = "(min-width:  768px) and (max-width:  991px)";
const viewport_sl = "(min-width:  992px) and (max-width: 1199px)";
const viewport_ml = "(min-width: 1200px) and (max-width: 1399px)";
const viewport_xl = "(min-width: 1400px)";

export const media_xs = window.matchMedia(viewport_xs);
export const media_sm = window.matchMedia(viewport_sm);
export const media_md = window.matchMedia(viewport_md);
export const media_sl = window.matchMedia(viewport_sl);
export const media_ml = window.matchMedia(viewport_ml);
export const media_xl = window.matchMedia(viewport_xl);

export const media_not_xs = window.matchMedia("(min-width: 576px)");

//*****************************************************************************
