
//*****************************************************************************

import * as getVar from "../common/page.vars.js";
import * as getFunc from "../common/page.functions.js";

import { ctrPageMessage } from "../common/page.message.js";

//*****************************************************************************

export const ctrUserSession = ( function () {

 "use strict";

 const setMessage = new ctrPageMessage;

 let sessionIntervalID = null;

 //****************************************************************************

 const ctrPageSession = function(obj) {

  const ref = this;

  ref.ctrSessionTimeout = function() {

   ref.setTimestampNow();

   document.addEventListener("click", ref.setTimestampNow);
   obj.sessionLogout.addEventListener("click", ref.setUserLogout);

   ref.setIntervalTimeout();

  }

  ref.setIntervalTimeout = function() {

   sessionIntervalID = setInterval( function() {

    const stampNow = getFunc.getTimestampNow();
    const stampCookie = getFunc.getCookieName(obj.sessionID);

    if(document.cookie.indexOf(obj.sessionID) === -1) { ref.setUserLogout(); } else

    if((stampNow - stampCookie) > obj.sessionTimeout) { ref.setUserLogout(); }

   }, obj.sessionInterval);

  }

  ref.setUserLogout = function() {

   const setLoader = new ref.setPageLoader();
   const setScreenSize = window.screen.width + "x" + window.screen.height;

   const jsonFormData = {

    "SessionType" : "Logout",
    "ScreenSize" : setScreenSize

   }

   fetch("./inc/class.user.session.php", {

    method: "POST",

    headers: {

     "Accept": "application/json",
     "Content-Type": "application/json"

    },

    body: JSON.stringify( jsonFormData )

   })

   .then(response => response.json())

   .then(response => {

    obj.sessionLogout.style.display = "none";

    setLoader.remove();

    ref.removeMenuLinks();
    ref.removeListener();

    if(response["Success"]) {

     document.addEventListener("transitionend", function transition() {

      setMessage.setConfirmMessage(response["Success"]);
      document.removeEventListener("transitionend",transition);

     });

    } else {

     getFunc.setSyslogMessage("Response Logout: " + response["Failed"]);

     document.addEventListener("transitionend", function transition() {

      setMessage.setAlertMessage("Fehler: " + response["Failed"]);
      document.removeEventListener("transitionend",transition);

     });

    }

   })

   .catch( (error) => {

    getFunc.setSyslogMessage("Error Logout: " + error);
    ref.removeListener();
    setLoader.remove();

   });

  }

  ref.setPageLoader = function() {

   const obj = this;

   obj.create = function() {

    const selectLoader = document.querySelector(".loader");

    if(selectLoader === null) {

     const div = document.createElement("div");
     div.classList.add("loader","spin");
     document.body.prepend(div);

    }

   }

   obj.remove = function() {

    const selectLoader = document.querySelector(".loader");

    if(selectLoader !== null) {

     setTimeout( function() {

      selectLoader.classList.add("hide");

      selectLoader.addEventListener("transitionend", function removeLoader() {

       selectLoader.remove();

       selectLoader.removeEventListener("transitionend",removeLoader);

      });

     }, 150);

    }

   }

   obj.create();

  }

  ref.setTimestampNow = function() {

   document.cookie = obj.sessionID + "=" + getFunc.getTimestampNow();

  }

  ref.removeMenuLinks = function() {

   const arrLoginLinks =document.querySelectorAll('[data-link="/djk-login"]');
   const arrLogoutLinks = document.querySelectorAll('[data-link="/kursadministration"]');

   arrLoginLinks.forEach( elem  => { elem.removeAttribute("style"); });
   arrLogoutLinks.forEach( elem  => { elem.remove(); });

   obj.sessionLogout.classList.add("hide");

   setTimeout( function() {

    const currentPage = getFunc.getCurrentPage();

    if(currentPage === "kursadministration") { window.location.href = "/"; }

   }, 2000);

  }

  ref.removeListener = function() {

   clearInterval(sessionIntervalID);
   obj.sessionLogout.removeEventListener("click", ref.setUserLogout);
   document.removeEventListener("click", ref.setTimestampNow);

  }

 }

 //****************************************************************************

 const init = function() {

  let obj = {};

  obj.sessionID = "DJKSESSID";

  obj.sessionInterval = getVar.sessionInterval;
  obj.sessionTimeout = getVar.sessionTimeout;
  obj.sessionLogout = document.querySelector(".contact-right .logout");

  const ref = new ctrPageSession(obj);

  ref.ctrSessionTimeout();

 }

 //****************************************************************************

 return {

  init : function () { init(); }

 }

});

//*****************************************************************************

// const setUserSession = new ctrUserSession;
//
// setUserSession.init();

//*****************************************************************************
