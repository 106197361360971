
//*****************************************************************************

import * as getVar from "../common/page.vars.js";
import * as getFunc from "../common/page.functions.js";

import * as getPort from "../common/page.media.ports.js";

//*****************************************************************************

export const ctrGoTopButtons = ( function () {

 const body = document.querySelector("body");
 const wrapper = document.querySelector(".wrapper");
 const btns = document.querySelector(".btns");
 const btnGoTop = document.querySelector(".btns-go-top");

 const scrollTopY = 1200;

 let btnRangeX = 298;
 let btnSpaceX = 218;
 let endSpaceY = 384;
 let setButtonType = null;

 const setGoToTopButtons = function (setType) {

  if(setButtonType === null) {

   setButtonType = setType;

  } else if(setButtonType !== setType) {

   setButtonType = setType;
   removeGoToTopButtons();

  }

  if(window.getComputedStyle(btns).display == "none") {

   const wrapperWidth = wrapper.offsetWidth;

   btns.style.display = "flex";
   btnGoTop.style.setProperty("padding-right",getVar.scrollbar + "px");

   if(setButtonType === "outside") {

    btnGoTop.classList.remove("inside");
    btns.classList.remove("inside");

    btns.style.width = (wrapperWidth + btnSpaceX) + "px";

   } else {

    btnGoTop.classList.add("inside");
    btns.classList.add("inside");

    btns.style.width = wrapperWidth + "px";

   }

   setTimeout( function() {

    btns.style.opacity = "1";

    window.addEventListener("resize",ctrToTopButtons);

   }, 10);

  }

 }

 const removeGoToTopButtons = function () {

  if(window.getComputedStyle(btns).display == "flex") {

   btns.style.opacity = "0";

   btns.addEventListener("transitionend", function transition() {

    btns.style.display = "none";
    btns.removeAttribute("style");

    btns.removeEventListener("transitionend",transition);

   });

  }

 }

 const ctrToTopButtons = function () {

  const scrollPosY = (Math.round(window.scrollY) + window.innerHeight);
  const wrapperWidth = wrapper.offsetWidth;
  const pageWidth = document.documentElement.clientWidth;

  let scrollBottomY = body.offsetHeight - endSpaceY;
  let scrollRangeSpace = false;

  let scrollRange = pageWidth - wrapperWidth;
  if(scrollRange >= btnRangeX) { scrollRangeSpace = true; }

  if(scrollPosY >= scrollTopY && scrollPosY <= scrollBottomY) {

   if(scrollRangeSpace) setGoToTopButtons("outside"); else setGoToTopButtons("inside");

  } else {

   removeGoToTopButtons();
   window.removeEventListener("resize",ctrToTopButtons);

  }

 }

 //****************************************************************************

 const init = function() {

  const media_xs = function(e) {

   if(e.matches) {

    btnRangeX = 198;
    btnSpaceX = 158;
    endSpaceY = 613;

    const btnOut = btns.querySelectorAll(".btn-outside");

    btnOut.forEach( item => {

     item.style.display = "none";

    });

   }

  }

  const media_sm = function(e) {

   if(e.matches) {

    btnRangeX = 198;
    btnSpaceX = 158;
    endSpaceY = 613;

    const btnOut = btns.querySelectorAll(".btn-outside");

    btnOut.forEach( item => {

     item.style.width = "49px";

    });

   }

  }

  const media_md = function(e) {

   if(e.matches) {

    btnRangeX = 198;
    btnSpaceX = 158;
    endSpaceY = 384;

    const btnOut = btns.querySelectorAll(".btn-outside");

    btnOut.forEach( item => {

     item.style.width = "49px";

    });

   }

  }

  const media_sl = function(e) {

   if(e.matches) {

    btnRangeX = 218;
    btnSpaceX = 158;
    endSpaceY = 384;

    const btnOut = btns.querySelectorAll(".btn-outside");

    btnOut.forEach( item => {

     item.style.width = "49px";

    });

   }

  }

  const media_ml = function(e) {

   if(e.matches) {

    btnRangeX = 218;
    btnSpaceX = 158;
    endSpaceY = 384;

    const btnOut = btns.querySelectorAll(".btn-outside");

    btnOut.forEach( item => {

     item.style.width = "49px";

    });

   }

  }

  const media_xl = function(e) {

   if(e.matches) {

    btnRangeX = 298;
    btnSpaceX = 218;
    endSpaceY = 384;

    const btnOut = btns.querySelectorAll(".btn-outside");

    btnOut.forEach( item => {

     item.style.width = "69px";

    });

   }

  }

  if(matchMedia) {

   const xs = getPort.media_xs;
   xs.addListener(media_xs);
   media_xs(xs);

   const sm = getPort.media_sm;
   sm.addListener(media_sm);
   media_sm(sm);

   const md = getPort.media_md;
   md.addListener(media_md);
   media_md(md);

   const sl = getPort.media_sl;
   sl.addListener(media_sl);
   media_sl(sl);

   const ml = getPort.media_ml;
   ml.addListener(media_ml);
   media_ml(ml);

   const xl = getPort.media_xl;
   xl.addListener(media_xl);
   media_xl(xl);

   window.addEventListener("scroll",ctrToTopButtons);

   ctrToTopButtons();

  }

 }

 return {

  init : function () { init(); }

 }

});

//*****************************************************************************

const setGoTopButtons = new ctrGoTopButtons;

setGoTopButtons.init();

//*****************************************************************************
