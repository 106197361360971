
//*****************************************************************************

import * as getVar from "../common/page.vars.js";
import * as getFunc from "../common/page.functions.js";

import { ctrUserSession } from "../common/page.user.session.js";
import { ctrPageMessage } from "../common/page.message.js";

//*****************************************************************************

export const ctrUserLogin = ( function () {

 "use strict";

 const setMessage = new ctrPageMessage;

 let setFormUser = null;
 let setFormPasswd = null;

 let setErrorTimeout  = null;

 //****************************************************************************

 const ctrPageLogin = function(obj) {

  const ref = this;

  obj.selectMenuDropdown = document.querySelector("ul.menu li ul");

  ref.ctrToggleLogin = function() {

   if(obj.elem.classList.contains("open")) {

    ref.closePageLogin();

   } else {

    const selectMobileMenu = document.querySelector(".mobile-menu");

    if(selectMobileMenu.classList.contains("open")) {

     ref.closeMobileMenu();

    } else {

     if(window.scrollY === 0) { ref.openPageLogin(); } else {

      getFunc.scrollToTop();

      window.addEventListener("scrollend", function scrolling() {

       ref.openPageLogin();
       window.removeEventListener("scrollend",scrolling);

      });

     }

    }

   }

  }

  ref.openPageLogin = function() {

   const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;

   ref.removeFormAlert();

   setTimeout( function() {

    obj.selectMenuDropdown.style.setProperty("display","none");

    obj.elem.classList.add("open");

    obj.elemLoginSlider.style.setProperty("right",getVar.scrollbar + "px");
    obj.elemLoginSlider.style.setProperty("transform","translateX(0%)");

    if(scrollBarWidth > 0) getFunc.setPageOverflow("overlay");

   }, 0);

   obj.elemLoginClose.addEventListener("click",ref.closePageLogin);
   obj.elemFormButton.addEventListener("click",ref.clickFormButton);
   obj.elemErrorClose.addEventListener("click",ref.removeErrorClass);

   setTimeout( function() { obj.selectMenuDropdown.removeAttribute("style"); }, 0);

  }

  ref.closePageLogin = function() {

   obj.selectMenuDropdown.style.setProperty("display","none");

   setTimeout( function() {

    obj.elem.classList.remove("open");

    obj.elemLoginSlider.style.setProperty("right","0");
    obj.elemLoginSlider.style.setProperty("transform","translateX(100%)");

    document.body.addEventListener("transitionend", function transition() {

     getFunc.removePageOverflow("overlay");

     ref.resetFormData();

     obj.elemLoginClose.removeEventListener("click",ref.closePageLogin);
     obj.elemFormButton.removeEventListener("click",ref.clickFormButton);
     obj.elemErrorClose.removeEventListener("click",ref.removeErrorClass);

     obj.elem.remove();

     document.body.removeEventListener("transitionend",transition);

    });

   }, 0);

   setTimeout( function() { obj.selectMenuDropdown.removeAttribute("style"); }, 0);


  }

  ref.clickFormButton = function() {

   if(ref.ctrFormValidation()) {

    const setLoader = new ref.setPageLoader();
    const setScreenSize = window.screen.width + "x" + window.screen.height;

    const jsonFormData = {

     "SessionType" : "Login",
     "FormLoginUser" : setFormUser,
     "FormLoginPassword" : setFormPasswd,
     "ScreenSize" : setScreenSize

    }

    fetch("./inc/class.user.session.php", {

     method: "POST",

     headers: {

      "Accept": "application/json",
      "Content-Type": "application/json"

     },

     body: JSON.stringify( jsonFormData )

    })

    .then(response => response.json())

    .then(response => {

     if(response["Success"]) {

      setLoader.remove();

      const setUserSession = new ctrUserSession;
      setUserSession.init();

      ref.closePageLogin();

      document.addEventListener("transitionend", function transition() {

       ref.setMenuLinks();

       setMessage.setConfirmMessage(response["Success"]);
       document.removeEventListener("transitionend",transition);

      });

     } else

     if(response["Incorrect"]) {

      ref.setFormError(response["Incorrect"]);
      setLoader.remove();

     } else

     if(response["Timeout"]) {

      ref.setFormError(response["Timeout"]);
      setLoader.remove();

     } else

     if(response["Existing"]) {

      ref.setFormError(response["Existing"]);
      setLoader.remove();

     } else {

      getFunc.setSyslogMessage("Error Login Form : " + response);
      ref.setFormError(response["Failed"]);
      setLoader.remove();

     }

    })

    .catch( (error) => {

     getFunc.setSyslogMessage("Error Login Form : " + error);
     ref.setFormError(getVar.msg.contact_form_error);
     setLoader.remove();

    });

   }

  }

  ref.ctrFormValidation = function() {

   setFormUser = obj.elemFormUser.value.toLowerCase().trim();
   setFormPasswd = obj.elemFormPasswd.value.trim();

   if(setFormUser === "") {

    ref.setFormError(getVar.msg.login_form_user_01);
    obj.elemFormUser.classList.add("error");
    return false;

   }

   if(setFormUser !== "") {

    if(setFormUser.length < 3) {

     getVar.msg.login_form_user_02 = getVar.msg.login_form_user_02.replace("#1","3");
     ref.setFormError(getVar.msg.login_form_user_02);
     obj.elemFormUser.classList.add("error");
     return false;

    }

    if(!setFormUser.match(getVar.form.regExName)) {

     ref.setFormError(getVar.msg.login_form_user_03);
     obj.elemFormUser.classList.add("error");
     return false;

    }

   }

   if(setFormPasswd === "") {

    ref.setFormError(getVar.msg.login_form_passwd_01);
    obj.elemFormPasswd.classList.add("error");
    return false;

   }

   if(setFormPasswd !== "") {

    if(setFormPasswd.length < 8) {

     getVar.msg.login_form_passwd_02 = getVar.msg.login_form_passwd_02.replace("#1","8");
     ref.setFormError(getVar.msg.login_form_passwd_02);
     obj.elemFormPasswd.classList.add("error");
     return false;

    }

    if(!setFormPasswd.match(getVar.form.regExPassword)) {

     ref.setFormError(getVar.msg.login_form_passwd_03);
     obj.elemFormPasswd.classList.add("error");
     return false;

    }

   }

   return true;

  }

  ref.setFormError = function(msg) {

   obj.elemFormUser.classList.remove("error");
   obj.elemFormPasswd.classList.remove("error");

   obj.elemFormError.style.display = "block";

   if(obj.elemFormError.classList.contains("show")) {

    obj.elemFormError.classList.remove("show");

    obj.elemFormError.addEventListener("transitionend", function transition() {

     obj.elemFormErrorMsg.innerHTML = msg;
     obj.elemFormError.classList.add("show");
     ref.removeFormError();

     obj.elemFormError.removeEventListener("transitionend",transition);

    });

   } else {

    obj.elemFormErrorMsg.innerHTML = msg;
    obj.elemFormError.classList.add("show");
    ref.removeFormError();

   }

  }

  ref.removeFormError = function() {

   if(setErrorTimeout !== null) {

    clearTimeout(setErrorTimeout);
    setErrorTimeout = null;

   }

   setErrorTimeout = setTimeout( function() {

    ref.removeErrorClass();

   }, 6000);

  }

  ref.removeErrorClass = function() {

   obj.elemFormError.classList.remove("show");

   obj.elemFormError.addEventListener("transitionend", function transition() {

    obj.elemFormError.style.display = "none";

    obj.elemFormError.removeEventListener("transitionend",transition);

   });

  }

  ref.resetFormData = function() {

   obj.elemFormUser.value = "";
   obj.elemFormPasswd.value = "";
   obj.elemFormUser.classList.remove("error");
   obj.elemFormPasswd.classList.remove("error");
   obj.elemFormError.classList.remove("show");

  }

  ref.closeMobileMenu = function() {

   const selectMobileMenu = document.querySelector(".mobile-menu");
   const selectNavMenu = selectMobileMenu.querySelector(".nav-menu");

   selectNavMenu.style.setProperty("right","0");
   selectNavMenu.style.setProperty("transform","translateX(100%)");

   selectNavMenu.addEventListener("transitionend", function transition() {

    selectMobileMenu.classList.remove("open");

    ref.ctrToggleLogin();

    selectNavMenu.removeEventListener("transitionend",transition);

   });

  }

  ref.setPageLoader = function() {

   const obj = this;

   obj.create = function() {

    const selectLoader = document.querySelector(".loader");

    if(selectLoader === null) {

     const div = document.createElement("div");
     div.classList.add("loader","spin");
     document.body.prepend(div);

    }

   }

   obj.remove = function() {

    const selectLoader = document.querySelector(".loader");

    if(selectLoader !== null) {

     setTimeout( function() {

      selectLoader.classList.add("hide");

      selectLoader.addEventListener("transitionend", function removeLoader() {

       selectLoader.remove();

       selectLoader.removeEventListener("transitionend",removeLoader);

      });

     }, 150);

    }

   }

   obj.create();

  }

  ref.setMenuLinks = function() {

   const arrLoginLinks = document.querySelectorAll('[data-link="/djk-login"]');

   arrLoginLinks.forEach( elem  => {

    const getElemClass = elem.getAttribute("class");

    const li = document.createElement("li");
    if(getElemClass) li.setAttribute("class",getElemClass);
    li.setAttribute("data-link","/kursadministration");
    li.innerHTML = "Kursadministration";
    elem.parentElement.appendChild(li);

    li.addEventListener("click", function() {

     window.location.href = "/kursadministration";

    });

    setTimeout( function() {

     window.location.href = "/kursadministration";

    }, 2000);

    elem.style.display = "none";

   });

   obj.elemLogout.classList.remove("hide");

  }

  ref.removeFormAlert = function() {

   if(document.getElementsByClassName("page-message").length) {

    document.querySelector(".page-message").remove();

   }

  }

 }

 //****************************************************************************

 const init = function() {

  let obj = {};

  obj.elem = document.querySelector(".djk-login");

  obj.elemLoginSlider = obj.elem.querySelector(".login-slider");
  obj.elemLoginClose = obj.elem.querySelector(".login-close");

  obj.elemFormUser = obj.elem.querySelector("#form-user");
  obj.elemFormPasswd = obj.elem.querySelector("#form-passwd");
  obj.elemFormButton = obj.elem.querySelector(".form-button button");
  obj.elemFormError = obj.elem.querySelector(".form-error");
  obj.elemErrorClose = obj.elem.querySelector(".form-error-close");
  obj.elemFormErrorMsg = obj.elem.querySelector(".form-error-msg");

  obj.elemLogout = document.querySelector(".contact-right .logout");

  const ref = new ctrPageLogin(obj);

  ref.ctrToggleLogin();

 }

 //****************************************************************************

 return {

  init : function () { init(); }

 }

});

//*****************************************************************************

// const setUserLogin = new ctrUserLogin;
//
// setUserLogin.init();

//*****************************************************************************
