
//*****************************************************************************

import * as getVar from "../common/page.vars.js";
import * as getFunc from "../common/page.functions.js";

//*****************************************************************************

export const ctrStatsClient = ( function () {

 const setPageTitle = document.querySelector("title").innerText;
 const setScreenSize = window.screen.width + "x" + window.screen.height;

 //****************************************************************************

 const setClientViews = function () {

  const request = new XMLHttpRequest();

  request.open("POST","./inc/class.stats.views.php",true);
  request.setRequestHeader("Content-Type","application/json;charset=UTF-8");

  const json = JSON.stringify({

   PageTitle : setPageTitle

  });

  request.onreadystatechange = function () {

   if(request.readyState == 4) {

    if(request.status == 200) {

     const result = JSON.parse(request.responseText);

     if(result.Success !== "Success") {

      getFunc.setSyslogMessage("Response Client Views: " + result.Success);

     }

    } else {

     getFunc.setSyslogMessage("Error Client Views: Request Status = " + request.status);

    }

   }

  };

  request.send(json);

 }

 //****************************************************************************

 // const setClientViews = function () {
 //
 //  const jsonClientData = {
 //
 //   "PageTitle" : setPageTitle
 //
 //  }
 //
 //  fetch("./inc/class.stats.views.php", {
 //
 //    method: "POST",
 //
 //    headers: {
 //
 //     "Accept": "application/json",
 //     "Content-Type": "application/json"
 //
 //    },
 //
 //    body: JSON.stringify( jsonClientData )
 //
 //   })
 //
 //  .then(response => response.json())
 //
 //  .then(response => {
 //
 //   if(!response["Success"]) {
 //
 //    getFunc.setSyslogMessage("Response Client Views: " + response["Success"]);
 //
 //   }
 //
 //  })
 //
 //  .catch( (error) => {
 //
 //   getFunc.setSyslogMessage("Error Client Views: " + error);
 //
 //  });
 //
 // }

 //****************************************************************************

 const setClientAgent = function () {

  const request = new XMLHttpRequest();

  request.open("POST","./inc/class.stats.agent.php",true);
  request.setRequestHeader("Content-Type","application/json;charset=UTF-8");

  const json = JSON.stringify({

   PageTitle : setPageTitle,
   ScreenSize : setScreenSize

  });

  request.onreadystatechange = function () {

   if(request.readyState == 4) {

    if(request.status == 200) {

     const result = JSON.parse(request.responseText);

     if(result.Success !== "Success") {

      getFunc.setSyslogMessage("Response Client Agent: " + result.Success);

     }

    } else {

     getFunc.setSyslogMessage("Error Client Agent: Request Status = " + request.status);

    }

   }

  };

  request.send(json);

 }

 //****************************************************************************

 // const setClientAgent = function () {
 //
 //  const jsonClientData = {
 //
 //   "PageTitle" : setPageTitle,
 //   "ScreenSize" : setScreenSize
 //
 //  }
 //
 //  fetch("./inc/class.stats.agent.php", {
 //
 //    method: "POST",
 //
 //    headers: {
 //
 //     "Accept": "application/json",
 //     "Content-Type": "application/json"
 //
 //    },
 //
 //    body: JSON.stringify( jsonClientData )
 //
 //   })
 //
 //  .then(response => response.json())
 //
 //  .then(response => {
 //
 //   if(!response["Success"]) {
 //
 //    getFunc.setSyslogMessage("Response Client Agent: " + response["Success"]);
 //
 //   }
 //
 //  })
 //
 //  .catch( (error) => {
 //
 //   getFunc.setSyslogMessage("Error Client Agent: " + error);
 //
 //  });
 //
 // }

 //****************************************************************************

 const getBrowserName = ( function (agent) {

  switch(true) {

   case agent.indexOf("edge") > -1: return "MS Edge";
   case agent.indexOf("edg/") > -1: return "Edge Chromium";
   case agent.indexOf("opr") > -1 && !!window.opr: return "Opera";
   case agent.indexOf("chrome") > -1 && !! window.chrome: return "Chrome";
   case agent.indexOf("trident") > -1: return "MS IE";
   case agent.indexOf("firefox") > -1: return "Mozilla Firefox";
   case agent.indexOf("safari") > -1: return "Safari";

   default: return 0;

  }

 });

 //****************************************************************************

 return {

  setClientViews : function () { setClientViews(); },
  setClientAgent : function () { setClientAgent(); }

 }

});

//*****************************************************************************

const setStatsClient = new ctrStatsClient;

setStatsClient.setClientViews();
setStatsClient.setClientAgent();

//*****************************************************************************
