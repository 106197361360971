
//*****************************************************************************

import * as getVar from "../common/page.vars.js";
import * as getFunc from "../common/page.functions.js";

import * as getPort from "../common/page.media.ports.js";

//*****************************************************************************

export const ctrHeadlineSlider = ( function () {

 const slider = document.querySelector(".headline-slider");
 const sliderTrack = slider.querySelector(".slider-track");
 const sliderBox = slider.querySelector(".slider-box");
 const slides = slider.querySelectorAll(".slide");

 const slideDesc = document.querySelector(".headline-desc .desc");
 const slideButton = slider.querySelector(".btn-slider");
 const slideButtonLink = slider.querySelector(".btn-link");
 const slideCounter = slider.querySelector(".control-counter");

 const arrowPrev = slider.querySelector(".control .control-prev .arrow");
 const arrowNext = slider.querySelector(".control .control-next .arrow");

 let prev = arrowPrev;
 let next = arrowNext;
 let slideWidth = slides[0].offsetWidth;
 let slideIndex = 0;
 let posInit = 0;
 let posX1 = 0;
 let posX2 = 0;
 let posY1 = 0;
 let posY2 = 0;
 let posFinal = 0;
 let isSwipe = false;
 let isScroll = false;
 let allowSwipe = true;
 let transition = true;
 let nextTrf = 0;
 let prevTrf = 0;
 let lastTrf = (slides.length -1) * slideWidth;
 let posThreshold = slides[0].offsetWidth * 0.15;
 let trfRegExp = /([-0-9.]+(?=px))/;
 let swipeStartTime;
 let swipeEndTime;

 let slideTitle = null;
 let slideLink = null;

 let resizeListener = false;

 //****************************************************************************

 const getEvent = function() {

  return (event.type.search("touch") !== -1) ? event.touches[0] : event;

 }

 const slide = function() {

  if(transition) { sliderBox.style.transition = "transform .5s"; }

  //***************************************************************************

  sliderBox.style.transform = `translate3d(-${slideIndex * 100}%, 0px, 0px)`;

  //***************************************************************************

  // sliderBox.style.transform = `translate3d(-${slideIndex * slideWidth}px, 0px, 0px)`;

  prev.classList.toggle("disabled", slideIndex === 0);
  next.classList.toggle("disabled", slideIndex === (slides.length - 1));

 }

 const swipeStart = function() {

  //***************************************************************************

  sliderBox.style.transform = `translate3d(-${slideIndex * slideWidth}px, 0px, 0px)`;

  //***************************************************************************

  let evt = getEvent();

  if(allowSwipe) {

   swipeStartTime = Date.now();

   transition = true;

   nextTrf = (slideIndex + 1) * -slideWidth;
   prevTrf = (slideIndex - 1) * -slideWidth;

   posInit = posX1 = evt.clientX;
   posY1 = evt.clientY;

   sliderBox.style.transition = "";

   document.addEventListener("touchmove", swipeAction);
   document.addEventListener("mousemove", swipeAction);
   document.addEventListener("touchend", swipeEnd);
   document.addEventListener("mouseup", swipeEnd);

   sliderTrack.classList.remove("grab");
   sliderTrack.classList.add("grabbing");

  }

 }

 const swipeAction = function() {

  let evt = getEvent(),
  style = sliderBox.style.transform,
  transform = + style.match(trfRegExp)[0];

  posX2 = posX1 - evt.clientX;
  posX1 = evt.clientX;

  posY2 = posY1 - evt.clientY;
  posY1 = evt.clientY;

  if(!isSwipe && !isScroll) {

   let posY = Math.abs(posY2);

   if(posY > 7 || posX2 === 0) {

    isScroll = true;
    allowSwipe = false;

   } else if (posY < 7) {

    isSwipe = true;
   }

  }

  if(isSwipe) {

   if(slideIndex === 0) {

    if(posInit < posX1) {

     setTransform(transform, 0);
     return;

    } else {

     allowSwipe = true;

    }
   }

   if(slideIndex === (slides.length -1)) {

    if(posInit > posX1) {

     setTransform(transform, lastTrf);
     return;

    } else {

     allowSwipe = true;

    }

   }

   if(posInit > posX1 && transform < nextTrf || posInit < posX1 && transform > prevTrf) {

    reachEdge();
    return;

   }

   sliderBox.style.transform = `translate3d(${transform - posX2}px, 0px, 0px)`;

  }

 }

 const swipeEnd = function() {

  posFinal = posInit - posX1;

  isScroll = false;
  isSwipe = false;

  document.removeEventListener("touchmove", swipeAction);
  document.removeEventListener("mousemove", swipeAction);
  document.removeEventListener("touchend", swipeEnd);
  document.removeEventListener("mouseup", swipeEnd);

  sliderTrack.classList.add("grab");
  sliderTrack.classList.remove("grabbing");

  if(allowSwipe) {

   swipeEndTime = Date.now();

   if(Math.abs(posFinal) > posThreshold || swipeEndTime - swipeStartTime < 300) {

    if(posInit < posX1) {

     slideIndex--;
     setOfferData();

    } else if (posInit > posX1) {

     slideIndex++;
     setOfferData();

    }

   }

   if(posInit !== posX1) {

    allowSwipe = false;
    slide();

   } else {

    allowSwipe = true;

   }

  } else {

   allowSwipe = true;

  }

 }

 const setTransform = function(transform, comapreTransform) {

  if(transform >= comapreTransform) {

   if(transform > comapreTransform) {

    sliderBox.style.transform = `translate3d(${comapreTransform}px, 0px, 0px)`;

   }

  }

  allowSwipe = false;

 }

 const reachEdge = function() {

  transition = false;
  swipeEnd();
  allowSwipe = true;

 }

 const getNumZeroFill = function(num,size) {

  num = num.toString();

  while (num.length < size) num = "0" + num;

  return num;

 }

 const setOfferCounter = function() {

  let counter = "";

  counter = counter += getNumZeroFill((slideIndex+1),2) + "/";
  counter = counter += getNumZeroFill(slides.length,2);

  slideCounter.style.opacity = 0;

  setTimeout( function(){

   slideCounter.innerHTML = counter;
   slideCounter.style.opacity = 1;

  }, 300);

 }

 const setOfferButton = function() {

  slideButton.style.opacity = 0;

  setTimeout( function(){

   slideButton.innerHTML = "<span>Angebot:&nbsp;</span>" + slideTitle;
   slideButtonLink.setAttribute("href",slideLink);
   slideButtonLink.setAttribute("title",slideTitle);

   slideButton.style.opacity = 1;

  }, 300);

 }

 const setOfferDesc = function() {

  slideDesc.style.opacity = 0;

  setTimeout( function(){

   slideDesc.innerHTML = slideTitle;
   slideDesc.style.opacity = 1;

  }, 300);

 }

 const setOfferData = function() {

  const slideImg = slides[slideIndex].querySelector("img");

  slideTitle = slideImg.getAttribute("title");
  slideLink = slideImg.getAttribute("data-link");

  setOfferDesc();
  setOfferCounter();
  setOfferButton();

 }

 //****************************************************************************

 const init = function(port) {

  sliderBox.style.transform = "translate3d(0px, 0px, 0px)";
  sliderBox.addEventListener("transitionend", () => allowSwipe = true);

  sliderTrack.classList.add("grab");

  slider.addEventListener("touchstart", swipeStart);
  slider.addEventListener("mousedown", swipeStart);

  prev.classList.add("disabled");

  prev.addEventListener("click", function() {

   slideIndex--;
   slide();
   setOfferData();

  });

  next.addEventListener("click", function() {

   slideIndex++;
   slide();
   setOfferData();

  });

  //***************************************************************************

  if (typeof ResizeObserver === "function") {

   const myObserver = new ResizeObserver( entries => {

	  entries.forEach(entry => {

	   slideWidth = slides[0].offsetWidth;
     slide();

	  });

   });

   myObserver.observe(sliderBox);

  } else {

   const mediaMatch = (e) => {

    if(e.matches) {

     if(resizeListener) {

      window.removeEventListener("resize",setOffsetData);
      resizeListener = false;

     }

     setOffsetData();

    }

   }

   const setOffsetData = function() {

    if(slideWidth != slides[0].offsetWidth) {

     slideWidth = slides[0].offsetWidth;
     slide();

    }

   }

   const setResizeListener = function() {

    resizeListener = true;
    window.addEventListener("resize", setOffsetData);

   }

   getPort.media_xs.addEventListener("change", (e) => {

    if(e.matches) { setResizeListener(); }

   });

   if(getPort.media_xs.matches) { setResizeListener(); }

   getPort.media_sm.addListener(mediaMatch);
   getPort.media_md.addListener(mediaMatch);
   getPort.media_sl.addListener(mediaMatch);
   getPort.media_ml.addListener(mediaMatch);
   getPort.media_xl.addListener(mediaMatch);

  }

  setOfferData();

 }

 return {

  init : function (port) { init(port); }

 };

});

//*****************************************************************************

const headlineSlider = new ctrHeadlineSlider;

headlineSlider.init();

//*****************************************************************************
