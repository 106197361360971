
//*****************************************************************************

import * as getVar from "../common/page.vars.js";
import * as getFunc from "../common/page.functions.js";

//*****************************************************************************

export const ctrStyleMode = ( function () {

 const styleModeEnabled = true;

 const styleModeKey = "data:mode";
 const styleModeLight = "light";
 const styleModeDark = "dark";

 let styleMode = null;

 //****************************************************************************

 const getStyleModeStorage = function() {

  const storageKeyValue = localStorage.getItem(styleModeKey);

  if(storageKeyValue === null) { getStyleModeClient(); } else {

   styleMode = storageKeyValue;

  }

  setStyleModeClient();

 }

 //****************************************************************************

 const getStyleModeClient = function() {

  if(window.matchMedia && window.matchMedia("(prefers-color-scheme:dark)").matches) {

   localStorage.setItem(styleModeKey,styleModeDark);
   styleMode = styleModeDark;

  } else {

   localStorage.setItem(styleModeKey,styleModeLight);
   styleMode = styleModeLight;

  }

 }

 //****************************************************************************

 const setStyleModeClient = function() {

  const selectBodyElement = document.querySelector("body");

  selectBodyElement.setAttribute("data-theme",styleMode);

  setStyleToggleIcon();

 }

 //****************************************************************************

 const setStyleModeToggle = function() {

  if(styleMode === styleModeLight) { styleMode = styleModeDark; } else {

   styleMode = styleModeLight

  }

  localStorage.setItem(styleModeKey,styleMode);

  setStyleModeClient();

 }

 //****************************************************************************

 const setStyleToggleIcon = function() {

  const selectToggleElement = document.querySelector(".theme-mode")

  if(styleMode == "light") {

   selectToggleElement.classList.remove("light");
   selectToggleElement.classList.add("dark");

  } else {

   selectToggleElement.classList.remove("dark");
   selectToggleElement.classList.add("light");

  }

 }

 //****************************************************************************

 const init = function() {

  if(document.querySelector(".theme-mode")) {

   const selectToggleElement = document.querySelector(".theme-mode");

   if(styleModeEnabled) {

    getStyleModeStorage();

    selectToggleElement.addEventListener("click", function() {

     setStyleModeToggle();

    });

   } else {

    selectToggleElement.classList.remove("light");
    selectToggleElement.classList.add("dark");

   }

  }

 };

 //****************************************************************************

 return {

  init : function () { init(); }

 };

});

//*****************************************************************************

const setStyleMode = new ctrStyleMode;

setStyleMode.init();

//*****************************************************************************
